.swiperBox {
    padding-bottom: 50% !important;
}
.headPadding{
    padding-top: env(safe-area-inset-top)!important;
    /*padding-bottom: env(safe-area-inset-bottom);*/
}
.footPadding{
    padding-bottom: env(safe-area-inset-bottom)!important;
    /*padding-bottom: env(safe-area-inset-bottom);*/
}
.headMargin{
    margin-top: env(safe-area-inset-top)!important;
    /*padding-bottom: env(safe-area-inset-bottom);*/
}
.bodyPadding{
    margin-top: env(safe-area-inset-top)!important;
    margin-bottom: env(safe-area-inset-bottom)!important;
    /*padding-bottom: env(safe-area-inset-bottom);*/
}
.superWinnerInnerBox {
    animation: WinnerMove 30s linear infinite;
    -webkit-animation: WinnerMove 30s linear infinite; /* Safari 和 Chrome */
}

@keyframes WinnerMove {
    100% {
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

img {
    max-width: 100%;
    display: block;
}

.tabHead img,.active-box img{
    display: inline;
}

.scorll {
    -webkit-overflow-scrolling: touch;
}

.agent-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px;
    text-align: center;
    background: url("../images/beagent/bj.jpg") no-repeat center;
    background-size: 100% 100%;
}

.agent-wrapper .agent-content {

    width: 100%;
    margin: 0 auto;
}

.agent-btns-wrapper {
    margin: -14% auto 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.agent-btns-wrapper .col {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100px;
    position: relative;
}

.agent-btns-wrapper .agent-img-btn {
    width: 81%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.agent-content-title {
    width: 100%;
    display: block;
    margin: 25% auto 0;
}

.scroll-content {
    padding-top: 0 !important;
}

.agent-btns-wrapper .agent-img-btn {
    animation: changesize 1s linear infinite;
    -moz-animation: changesize 1s linear infinite; /* Firefox */
    -webkit-animation: changesize 1s linear infinite; /* Safari 和 Chrome */
    -o-animation: changesize 1s linear infinite; /* Opera */
}

@keyframes changesize {
    0% {
        width: 81%;
    }
    50% {
        width: 91%;
    }
    100% {
        width: 81%;
    }
}

@-moz-keyframes changesize /* Firefox */
{
    0% {
        width: 81%;
    }
    50% {
        width: 91%;
    }
    100% {
        width: 81%;
    }
}

@-webkit-keyframes changesize /* Safari 和 Chrome */
{
    0% {
        width: 81%;
    }
    50% {
        width: 91%;
    }
    100% {
        width: 81%;
    }
}

@-o-keyframes changesize /* Opera */
{
    0% {
        width: 81%;
    }
    50% {
        width: 91%;
    }
    100% {
        width: 81%;
    }
}

.refresh {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: refresh 1s linear infinite;
    -moz-animation: refresh 1s linear infinite; /* Firefox */
    -webkit-animation: refresh 1s linear infinite; /* Safari 和 Chrome */
    -o-animation: refresh 1s linear infinite; /* Opera */
}

@keyframes refresh {
    100% {
        transform: rotate(180deg);
        transform-origin: center;
    }
}
.noticeContainer{}
.noticeTextBox {
    white-space: nowrap;
    transform: translateX(100%);
    animation: textScroll 10s linear infinite;
    -moz-animation: textScroll 10s linear infinite; /* Firefox */
    -webkit-animation: textScroll 10s linear infinite; /* Safari 和 Chrome */
    -o-animation: textScroll 10s linear infinite; /* Opera */
}

@keyframes textScroll {
    100% {
        transform: translateX(-100%);
    }
}
.toast {
    display: flex;
    justify-content: center;
    align-items: center;
}


.viewport {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 0;
    -webkit-transform: translateZ(0);
}

.flipsnap {
    width: 1145px;
    /* 230px(item) * 5 + 45px(padding) */
    /*padding-left: 45px;*/
}

.flipsnap:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
}

.item {
    width: 320px; /*可以计算或者传过去*/
    margin: 0 10px; /*控制卡片的间距*/
    text-align: center;
    padding: 50px 0;
    background: #efefef;
    border: 5px solid #999;
    float: left;
    color: #666;
    box-sizing: border-box;
}

