body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
    height: auto;
}

table {
    width: 100% !important;
    border-spacing: 0 !important;
    border-collapse: collapse !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    text-align: center;
    font-family: "\5FAE\8F6F\96C5\9ED1";
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
    /*background: url("src/assets/images/login-bg.jpg") no-repeat center;*/
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@charset "UTF-8"
/*……SKIN1……*/
/*……SKIN2……*/
/*……SKIN3……*/
/*……SKIN4……*/
/*……SKIN5……*/
/*……SKIN6……*/
/*……SKIN7……*/
/*……SKIN8……*/
/*……SKIN9……*/



.toast_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 999;

}

.toast_box {
    bottom: 100px;
    position: fixed;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 6px;
    color: #fff;
    padding: 10px 10px;
    z-index: 999;
    max-width: 90%;
}

.toast_text {
    text-align: center;
    overflow: hidden;
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    font-size: 14px;
}

.toast_icon {
    position: relative;
    left: 50%;
    top: 15%;
    margin: -.4rem;
    width: .8rem;
    height: .8rem;
}

.toast_loading {
    -webkit-animation: loading 1s steps(12, end) infinite;
    animation: loading 1s steps(12, end) infinite;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAENCA8KAgsGDgQMCQUDBwhylaLQAAAL1JREFUOMu9U0kSwyAMK9jsS/T/1zZt2pgEZzq9RBeMZYRGDI/70bO5JptjrOAQVTonIJVK5bW2ma9A7VvpK8OdeQfbZectrDnyU+Oo0b68wGK0muDPdxpOciaizq5pkAgiIPAoew2rBVNYZoM2YHbZDNKz/2Ogam3ff5gMEL8wisfh2KKZiFiGWFkk1B7NSbhNQFy4M2+PghbODNsg7y8THM2njiy8gBgcaEUA9GgNJbxh6fJv+NxiFvYmPAFtCQZNK1qZIAAAAABJRU5ErkJggg==") no-repeat;
    background-size: 100%;
}

.toast_success {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMA8DAQ0GBP4LCggMBwIJBAIttdjAAAAINJREFUOMvdkUsOwyAMBbH5hUCauf9pK1SlohF438x2LPn52f09+8vUfiNb/gighj8FouEjYCUoQDXiBSD7pdcMiK7XC9wCFmlDO3T20Scgx287ne13pwDU89NOJ3g3maCmJDANqIGRtLj8oi1ed1GMdmcB7wXIYX8QdQZJiM5Em3smbyVICDCOrCqSAAAAAElFTkSuQmCC") no-repeat;
    background-size: 100%;
}

.toast_error {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAMFBMVEUAAAD///////////////////////////////////////////////////////////87TQQwAAAAD3RSTlMA0BDAMODwUKBgsCCAQJClzVPvAAAA0UlEQVQoz2MgErAclv9o44Dgc8b/B4KvBTA+t/3XdgeWivjPG6ACbl8ngNXlp0AN+L8IwtD6DzFm2w+Y3v5sMGW/ACbA9Rms9ZsCTIApH2QR608GhoUKQJ4xA8P8AKCAP5CwF2JgUPwIlPwCFDj/AMRRYJIHCnL8AZkJ1AfkAcUYGNhBpso7MICUgBQw8H4EEv/B5ssDFYA4mAKYWjANfd+Aai3CYZ9BDoM63RDkdEGQ0zE9h+l9zADCDEIGt2/wQEZEwwVepGhgYEdEFGZUEgYAW05XI3jSsVwAAAAASUVORK5CYII=") no-repeat;
    background-size: 100%;
}

.toast_info {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAALVBMVEUAAAD///////////////////////////////////////////////////////+hSKubAAAADnRSTlMA4CCAwKBAMJBg8NAQUNhWlbcAAAC+SURBVCjPYyASsLfse+1cgOBzyr0DgocXYHwmv4dtCkwZck8UoAJZDydA1C2H8NnexUAYR99BjNF6CtMbtwhM+QUACUUhIMH6BKz14QEgafcYSPDIgSxifMkAE2CYJwAk6gQQAozPgURfA0KAA0T6JSAE2ECm7lNACDC9BhLvGGACIA6GAFyLohBEC9xQqLeeQKwFA4i1EIfBAeNzuNMVhSBOx/AcpvcxAwgzCDEDGTMaGHhhEYWIShN4VBIGAPvRT5YzufhUAAAAAElFTkSuQmCC") no-repeat;
    background-size: 100%;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

.alert_bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert_box {
    width: 70%;
    border-radius: 6px;
    background: #fff;
}

.alert_title {
    text-align: center;
    font-size: 14px;
    color: #000;
    padding: 10px 10px 0;
}

.alert_content {
    padding: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 19px;
}

.alert_content_fac{
    text-align: center;
}

.alert_content_fal{
    text-align: left;
}
.alert_content_far{
    text-align: right;
}
.alert_content_pl4{
    padding-left: 18px;
}

.alert_button_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #f5f7f9;
}

.alert_button {
    padding: 10px;
    display: flex;
    flex: 1 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.alert_button:first-child{
    border-right: 1px solid #f5f7f9;
}


.swiperBox {
    padding-bottom: 50% !important;
}
.headPadding{
    padding-top: env(safe-area-inset-top)!important;
    /*padding-bottom: env(safe-area-inset-bottom);*/
}
.footPadding{
    padding-bottom: env(safe-area-inset-bottom)!important;
    /*padding-bottom: env(safe-area-inset-bottom);*/
}
.headMargin{
    margin-top: env(safe-area-inset-top)!important;
    /*padding-bottom: env(safe-area-inset-bottom);*/
}
.bodyPadding{
    margin-top: env(safe-area-inset-top)!important;
    margin-bottom: env(safe-area-inset-bottom)!important;
    /*padding-bottom: env(safe-area-inset-bottom);*/
}
.superWinnerInnerBox {
    animation: WinnerMove 30s linear infinite;
    -webkit-animation: WinnerMove 30s linear infinite; /* Safari 和 Chrome */
}

@-webkit-keyframes WinnerMove {
    100% {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@keyframes WinnerMove {
    100% {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

img {
    max-width: 100%;
    display: block;
}

.tabHead img,.active-box img{
    display: inline;
}

.scorll {
    -webkit-overflow-scrolling: touch;
}

.agent-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px;
    text-align: center;
    background: url(../../static/media/bj.00620a46.jpg) no-repeat center;
    background-size: 100% 100%;
}

.agent-wrapper .agent-content {

    width: 100%;
    margin: 0 auto;
}

.agent-btns-wrapper {
    margin: -14% auto 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.agent-btns-wrapper .col {
    flex: 1 1;
    display: flex;
    align-items: center;
    height: 100px;
    position: relative;
}

.agent-btns-wrapper .agent-img-btn {
    width: 81%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.agent-content-title {
    width: 100%;
    display: block;
    margin: 25% auto 0;
}

.scroll-content {
    padding-top: 0 !important;
}

.agent-btns-wrapper .agent-img-btn {
    animation: changesize 1s linear infinite;
    -moz-animation: changesize 1s linear infinite; /* Firefox */
    -webkit-animation: changesize 1s linear infinite; /* Safari 和 Chrome */
    -o-animation: changesize 1s linear infinite; /* Opera */
}

@keyframes changesize {
    0% {
        width: 81%;
    }
    50% {
        width: 91%;
    }
    100% {
        width: 81%;
    }
}

@-webkit-keyframes changesize /* Safari 和 Chrome */
{
    0% {
        width: 81%;
    }
    50% {
        width: 91%;
    }
    100% {
        width: 81%;
    }
}

.refresh {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: refresh 1s linear infinite;
    -moz-animation: refresh 1s linear infinite; /* Firefox */
    -webkit-animation: refresh 1s linear infinite; /* Safari 和 Chrome */
    -o-animation: refresh 1s linear infinite; /* Opera */
}

@-webkit-keyframes refresh {
    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        -webkit-transform-origin: center;
                transform-origin: center;
    }
}

@keyframes refresh {
    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        -webkit-transform-origin: center;
                transform-origin: center;
    }
}
.noticeContainer{}
.noticeTextBox {
    white-space: nowrap;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    animation: textScroll 10s linear infinite;
    -moz-animation: textScroll 10s linear infinite; /* Firefox */
    -webkit-animation: textScroll 10s linear infinite; /* Safari 和 Chrome */
    -o-animation: textScroll 10s linear infinite; /* Opera */
}

@-webkit-keyframes textScroll {
    100% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}

@keyframes textScroll {
    100% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}
.toast {
    display: flex;
    justify-content: center;
    align-items: center;
}


.viewport {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px 0;
    -webkit-transform: translateZ(0);
}

.flipsnap {
    width: 1145px;
    /* 230px(item) * 5 + 45px(padding) */
    /*padding-left: 45px;*/
}

.flipsnap:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
}

.item {
    width: 320px; /*可以计算或者传过去*/
    margin: 0 10px; /*控制卡片的间距*/
    text-align: center;
    padding: 50px 0;
    background: #efefef;
    border: 5px solid #999;
    float: left;
    color: #666;
    box-sizing: border-box;
}


/*
   Animation example, for spinners
*/
.animate-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

